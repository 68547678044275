import { useMutation } from '@apollo/client';
import {
    Brightness1, Dashboard, ExitToApp, Groups, MoreVert as MoreIcon, Person
} from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import {
    AppBar, Box, Button, Divider, Grid, IconButton, Link, List,
    ListItem, ListItemText, Menu, MenuItem, Popover,
    Toolbar, Tooltip, Typography, Zoom, ListItemIcon, ListItemButton, Avatar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconMoon, IconSun } from '@tabler/icons';
import { Auth } from 'aws-amplify';
import React, { useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AvatarName from '../common/avatarname/AvatarName';
import { ThemeContext } from '../common/context/ThemeContext';
import { UserContext } from '../common/context/UserContext';
import { UPDATETHEME } from '../GraphQL/Mutation';
import { AuctionStatus, Roles, AppHostDomain } from '../common/StaticVariableDeclaration';
import AuctionTimer from '../bidder/bid-process/AuctionTimer';
import AuctionCurrentTimer from '../common/AuctionCurrentTimer';
import { CgMenuGridO } from 'react-icons/cg';
import { AxiosAuctionInterceptor } from '../config/AxiosAuctionInterceptor';
import AuctionSnackBar from '../common/AuctionSnackBar';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportDrawer from '../common/support-drawer/SupportDrawer';
import QuickSettingsDrawer from '../common/quick-settings-drawer/QuickSettingsDrawer';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

const useStyles = makeStyles((theme) => ({
    popOverIcon: {
        minWidth: '32px'
    },
    bgAppBar: theme.topBarBg,
    menuButton: {
        fontWeight: '600 !important',
        display: 'block !important',
        textTransform: 'capitalize !important',
        padding: '5px 10px !important',
        margin: '0px 10px !important'

    },
    menuButtonIcon: {
        verticalAlign: 'bottom !important',
        paddingRight: '2px !important'
    },
    activeMenuButton: {
        backgroundColor: theme.selectedBg,
        border: `1px solid ${theme.step.contentBorderColor} !important`
    },
    popUpIcon: {
        borderRadius: '10px',
        backgroundColor: theme.eVotingSwitchColorTheme.backgroundColor,
        borderColor: theme.eVotingSwitchColorTheme.borderColor,
        color: theme.eVotingSwitchColorTheme.color,
        height: '40px',
        width: "40px",
        cursor: "pointer"
    },
    voteImageClass: {
        width: '32px',
        cursor: 'pointer',
        position: 'relative',
        top: '2px',
        right: '5px',
        height: '30px'
    },
    headerIcons: {
        padding: '8px !important',
        color: '#b1c5df !important'
    }

}))

const TopBar = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const themeContext = useContext(ThemeContext);
    const userContext = useContext(UserContext);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const openMenuLink = Boolean(anchorElMenu);
    const menuLinkId = openMenuLink ? 'link-menu-popover' : undefined
    const [message, setMessage] = useState({
        showMsg: false,
        message: null,
        severity: null
    })
    const isCustomerSupport = (userContext?.rootContext.loginUserRoles.find((role) => { return role === Roles.enkSupport })) ? true : false;
    const isAdmin = (userContext?.rootContext.loginUserRoles.find((role) => { return role === Roles.enkAdmin })) ? true : false;
    const [supportDrawerState , setSupportDrawerState] = useState(false); 
    const [settingsDrawerState , setSettingsDrawerState] = useState(false);

    const navigateToAuctionList = () => {
        // userContext.setSelectedMenu('auction');
        if(userContext.rootContext.selectedMenu != 'auction') {
            userContext.setRootContext(prevState => ({
                ...prevState,
                selectedMenu: 'auction'
              })
            );
        }
        history.push({
            pathname: userContext.rootContext.userSession.isAuthenticated ? "/auction-list" : "/landing-page",
            state: {
                userRole: [],
                auctionListType: userContext.rootContext.userSession.isAuthenticated ? AuctionStatus.open : AuctionStatus.scheduled,
                pagination: {
                    page: 1,
                    pageSize: 10
                },
                isMyBid: false
            }
        })
    }

    // const navigateToRoles = () =>{
    //     history.push("/auction-roles")
    //     handleClose()
    // }

    const navigateToBiddingsHall = () => {
        // userContext.setSelectedMenu('biddingHall');
        history.push("/bidders-page")
    }

    const navigateToMyAuctionList = () => {
        // userContext.setSelectedMenu('myAuction');
        if(userContext.rootContext.selectedMenu != 'myAuction') {
            userContext.setRootContext(prevState => ({
                    ...prevState,
                    selectedMenu: 'myAuction'
                })
            );
        }
        history.push({
            pathname: "/my-auction-list",
            state: {
                userRole: [Roles.auctioneer, Roles.support],
                auctionListType: AuctionStatus.open,
                pagination: {
                    page: 1,
                    pageSize: 10
                },
                isMyBid: false
            }
        })
    }

    const navigateToMyBidsList = () => {
        // userContext.setSelectedMenu('myBids');
        if(userContext.rootContext.selectedMenu != 'myBids') {
            userContext.setRootContext(prevState => ({
                ...prevState,
                selectedMenu: 'myBids'
                })
            );
        }
        history.push({
            pathname: "/my-bids-list",
            state: {
                userRole: [Roles.bidder, Roles.bidderAuthorizedRepresentative, Roles.requestedBidder, Roles.rejectedBidder],
                auctionListType: AuctionStatus.open,
                pagination: {
                    page: 1,
                    pageSize: 10
                },
                isMyBid: true
            }
        })
    }

    const navigateToMultiAuctionBidder = () => {
        if(userContext.rootContext.selectedMenu != 'multiAuctionBidder') {
            userContext.setRootContext(prevState => ({
                ...prevState,
                selectedMenu: 'multiAuctionBidder'
                })
            );
        }
        history.push({
            pathname: "/multi-auction-bidder",
            state: {
                userRole: [Roles.bidder, Roles.bidderAuthorizedRepresentative, Roles.requestedBidder, Roles.rejectedBidder],
                auctionListType: AuctionStatus.open,
                pagination: {
                    page: 1,
                    pageSize: 10
                }
            }
        })
    }

    const logout = async event => {
        event.preventDefault();
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
        try {
            // await Auth.signOut().then(res => {
            //     userContext.setUserSession({ isAuthenticated: false, session: null });
            //     userContext.setLoginUserRoles([]);
            //     userContext.setCurrentDateTime();
            //     sessionStorage.clear();
            //     history.push({
            //         pathname: "/landing-page",
            //         state: {
            //             userRole: [],
            //             auctionListType: AuctionStatus.scheduled,
            //             pagination: {
            //                 page: 1,
            //                 pageSize: 10
            //             }
            //         }
            //     })
            // })
            AxiosAuctionInterceptor.post('revoke', {
                token: sessionStorage.getItem('refreshToken')
            }).then(response => {
                sessionStorage.clear();
                history.push("/goto-landing");
                // userContext.setReloadApp(false);
                // userContext.setUserSession({ isAuthenticated: false, session: null });
                // userContext.setLoginUserRoles([]);
                // userContext.setCurrentDateTime();
                // userContext.setReloadApp(true);
                // history.push({
                //     pathname: "/landing-page",
                //     state: {
                //         userRole: [],
                //         auctionListType: AuctionStatus.scheduled,
                //         pagination: {
                //             page: 1,
                //             pageSize: 10
                //         }
                //     }
                // })
            }).catch((error) => {
                if (error.message) {
                    setMessage({showMsg: true, message: error.message, severity: 'error'});
                } else {
                    setMessage({showMsg: true, message: t('Try_Again'), severity: 'error'});
                }
            })
        } catch (error) {
            console.log('error', error);
        }
    }

    const profile = async event => {
        // userContext.setSelectedMenu('profile');
        if(userContext.rootContext.selectedMenu != 'profile') {
            userContext.setRootContext(prevState => ({
                ...prevState,
                selectedMenu: 'profile'
            })
            );
            event.preventDefault();
            history.push("/profile");
        }
        handleClose()
    }

    const client = async event => {
        // userContext.setSelectedMenu('client');
        if(userContext.rootContext.selectedMenu != 'client') {
            userContext.setRootContext(prevState => ({
                ...prevState,
                selectedMenu: 'client'
            })
            );
            event.preventDefault();
            history.push("/client");
        }
        handleClose()
    }

    const NavigateToRoles = (event) => {
        event.preventDefault();
        history.push("/roles");
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const [disable, setDisable] = useState(false);

    const onLogin = () => {
        history.push("/sign-in")
    }

    const onSignUp = () => {
        history.push("/sign-up")
    }

    // const [setAndGetThemes, { loading, error }] = useMutation(THEME, {
    //     errorPolicy: 'all',
    //     onCompleted: (data) => {
    //         console.log("data", data);
    //         themeContext.dispatch({ type: data.themeAndColor.theme })
    //     },
    //     onError: (error) => {
    //         console.log("error", error);
    //     }
    // });

    // const updateTheme = (event, mode) => {
    //     event.preventDefault();
    //     console.log(mode);
    //     setAndGetThemes({
    //         variables: {
    //             banner_color: '',
    //             theme: mode
    //         }
    //     })
    // };

    const [setAndGetThemes, { loading: themeLoading, error: themeError }] = useMutation(UPDATETHEME, {
        errorPolicy: 'all',
        onCompleted: (data) => {
        },
        onError: (error) => {
            console.log("error", error);
        }
    });

    const updateTheme = (event, mode) => {
        event.preventDefault();
        themeContext.dispatch({ type: mode })
        setAndGetThemes({
            variables: {
                color: {
                    theme: mode,
                    bannerColor: ''
                }
            }
        })
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Popover
            id={menuId}
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <Grid container>
                <Grid item xs={12}>
                    <List component="nav" aria-label="addresses">
                        <ListItem id="navwebprofile" button onClick={profile} key={1} className={classes.popOverIcon}>
                            <Person style={{ marginRight: '1rem' }} />
                            <ListItemText primary={t('My_Profile')}></ListItemText>
                        </ListItem>
                        <ListItem id="navwebclient" button onClick={client} key={2} className={classes.popOverIcon}>
                            <Groups style={{ marginRight: '1rem' }} />
                            <ListItemText primary={t('My_Clients')}></ListItemText>
                        </ListItem>
                        <ListItem id="navwebsignout" button key={3} onClick={logout}>
                            <ExitToApp style={{ marginRight: '1rem' }} />
                            <ListItemText primary={t('Sign_Out')}></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Popover>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            {/* Start of Theme setting */}
            {/* <Typography sx={{ paddingLeft: '10px' }}>Theme Settings</Typography>
            <MenuItem>
                <Tooltip TransitionComponent={Zoom} title="Light">
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <IconButton size="large"
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            id="lighttheme"
                            color="inherit" onClick={(event) => updateTheme(event, 'light')}>
                            <IconSun
                                value="light"
                                size={32}
                                strokeWidth={2}
                                color={'#ced279'}
                            />
                        </IconButton>
                    </Box>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Dim">
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <IconButton id="dimtheme" onClick={(event) => updateTheme(event, 'dim')}>
                            <IconMoon
                                size={25}
                                strokeWidth={2}
                                color={'#4057bf'}
                            />
                        </IconButton>
                    </Box>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title="Dark">
                    <Box sx={{ display: { xs: 'flex' } }}>
                        <IconButton id="darktheme" onClick={(event) => updateTheme(event, 'dark')}>
                            <Brightness1 style={{ color: 'black', borderRadius: '50%', border: '1px solid #fff' }}
                                size={45}
                                strokeWidth={1}
                                color={'#e2e6b3'}
                            />
                        </IconButton>
                    </Box>
                </Tooltip>
            </MenuItem>
            <Divider orientation="horizontal" /> */}
            {/* End of Theme setting */}
            <Typography sx={{ paddingLeft: '10px' }}>Profile Settings</Typography>
            {/* <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    id="profilesetting"
                >
                    <AvatarName />
                </IconButton>
            <p>Profile</p>
            </MenuItem> */}
            <List component="nav" aria-label="addresses">
                <ListItem id="navmobprofile" button onClick={profile} className={classes.popOverIcon}>
                    <Person style={{ marginRight: '1rem' }} />
                    <ListItemText primary={t('My_Profile')}></ListItemText>
                </ListItem>
                <ListItem id="navmobclient" button onClick={client} className={classes.popOverIcon}>
                    <Groups style={{ marginRight: '1rem' }} />
                    <ListItemText primary={t('My_Clients')}></ListItemText>
                </ListItem>
                <ListItem id="navmobsignout" button key={4} onClick={logout}>
                    <ExitToApp style={{ marginRight: '1rem' }} />
                    <ListItemText primary={t('Sign_Out')}></ListItemText>
                </ListItem>
            </List>
        </Menu>
    );

    const handleMenuClick = (event) => {
        setAnchorElMenu(event.currentTarget);
    }

    const handleMenulinkClose = () => {
        setAnchorElMenu(null);
    }

    const handleContactUs = () => {
        history.push("/contact-us")
    }

    const toggleDrawer = (type, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(type === 'settings') {
            setSettingsDrawerState(open);
        }
        if(type === 'support') {
            setSupportDrawerState(open);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="fixed"
                elevation={0}
                className={classes.bgAppBar}
            // sx={{ boxShadow: '0px 1px 4px 0 rgb(0 0 0 / 30%), 0 0 0 0px rgb(0 0 0 / 10%) !important' }}
            // style={{
            //     boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 10px 10px 1px rgb(0 0 0 / 4%), 0px 10px 10px 2px rgb(0 0 0 / 4%)',
            //     borderBottom: '1px solid #d8d8d84d',
            // }}
            >
                <Toolbar variant='dense'>

                    {/* start of link to evoting */}
                    <Tooltip title={t('Switch_to')}>
                        <IconButton aria-label="menu" onClick={handleMenuClick} style={{ right: '10px', padding: '3px' }}>
                            <CgMenuGridO fontSize='30px' />
                        </IconButton>
                    </Tooltip>
                    <Popover
                        id={menuLinkId}
                        open={openMenuLink}
                        anchorEl={anchorElMenu}
                        onClose={handleMenulinkClose}
                        positionLeft={12}
                        PaperProps={{
                            style: { width: '20%' },
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        anchorPosition={{
                            top: 49,
                            left: 11
                        }}
                        anchorReference='anchorPosition'
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <List component="nav" aria-label="switch">
                                    <ListItem>
                                        <ListItemText id="switch-to"
                                            primary={<Typography variant="body1" style={{ fontWeight: 'bold' }}>{t('Switch_to')}</Typography>} />
                                    </ListItem>
                                    <Link target="_blank" href={process.env.REACT_APP_EVOTING_URL} style={{ textDecoration: 'none' }} >
                                        <ListItemButton key={1} id="link-to-evoting">
                                            <img id="menuIcon" height={45} width={150} src={process.env.PUBLIC_URL + './images/evote-logo.svg'} alt='TRUSTeVote' />
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Grid>
                        </Grid>
                    </Popover>

                    {/* end of link to evoting */}

                    {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{ mr: 2 }}
                    >
                    <MenuIcon />
                    </IconButton> */}
                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.enkindle && <img src={process.env.PUBLIC_URL + './images/auction.svg'} height={45} width={150} alt='TRUSTeAuctionLogo' style={{ padding: '3px 10px 3px 3px' }}></img> }
                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + './images/nbid.png'} height={38} width={92} alt='NbidLogo' style={{ padding: '3px 10px 3px 3px' }}></img> }
                    {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>*/}
                    {/* {userContext.userSession.isAuthenticated && <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                        <Button
                            onClick={navigateToDashBoard}
                            id="menu-nav-dashboard"
                            sx={{ my: 2 }}
                            aria-controls={menuId}
                            className={userContext.selectedMenu == 'dashboard' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                        >{t('Dashboard')}
                        </Button>
                    </Box>}
                    {userContext.userSession.isAuthenticated && <Box sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
                        <Tooltip arrow title={t('Dashboard')}>
                            <Button
                                id="menu-nav-mob-dashboard"
                                onClick={navigateToDashBoard}
                                sx={{ my: 2 }}
                                aria-controls={mobileMenuId}
                                className={userContext.selectedMenu == 'dashboard' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                            ><Dashboard />
                            </Button>
                        </Tooltip>
                    </Box>} */}
                    <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                        <Button
                            id="menu-nav-auction"
                            onClick={navigateToAuctionList}
                            sx={{ my: 2 }}
                            aria-controls={menuId}
                            className={userContext.rootContext.selectedMenu == 'auction' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                        >{<PublicIcon className={classes.menuButtonIcon} />}{t('Auction')}
                        </Button>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}>
                        <Tooltip arrow title={t('Auction')}>
                            <Button
                                id="menu-nav-mob-auction"
                                onClick={navigateToAuctionList}
                                sx={{ my: 2 }}
                                aria-controls={mobileMenuId}
                                className={userContext.rootContext.selectedMenu == 'auction' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                            >{<PublicIcon className={classes.menuButtonIcon} />}
                            </Button>
                        </Tooltip>
                    </Box>
                    {userContext.rootContext.userSession.isAuthenticated && !isCustomerSupport && !isAdmin &&
                        <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                            <Button
                                id="menu-nav-mybids"
                                onClick={navigateToMyBidsList}
                                sx={{ my: 2 }}
                                aria-controls={menuId}
                                className={userContext.rootContext.selectedMenu == 'myBids' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                            >{<PersonIcon className={classes.menuButtonIcon} />}{t('My_Bids')}
                            </Button>
                        </Box>
                    }
                    {userContext.rootContext.userSession.isAuthenticated && !isCustomerSupport && !isAdmin &&
                        <Box sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}>
                            <Tooltip arrow="true" title={t('My_Bids')}>
                                <Button
                                    id="menu-nav-mob-mybids"
                                    onClick={navigateToMyBidsList}
                                    sx={{ my: 2 }}
                                    aria-controls={mobileMenuId}
                                    className={userContext.rootContext.selectedMenu == 'myBids' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                                >{<PersonIcon className={classes.menuButtonIcon} />}
                                </Button>
                            </Tooltip>
                        </Box>
                    }
                    {userContext.rootContext.userSession.isAuthenticated && (isAdmin || userContext.rootContext.isUserAuctioneer > 0) &&
                        <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                            <Button
                                id="menu-nav-myauction"
                                onClick={navigateToMyAuctionList}
                                sx={{ my: 2 }}
                                aria-controls={menuId}
                                className={userContext.rootContext.selectedMenu == 'myAuction' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                            >{<PersonIcon className={classes.menuButtonIcon} />}{t('My_Auction')}
                            </Button>
                        </Box>
                    }
                    {userContext.rootContext.userSession.isAuthenticated && (isAdmin || userContext.rootContext.isUserAuctioneer > 0) &&
                        <Box sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}>
                            <Tooltip arrow="true" title={t('My_Auction')}>
                                <Button
                                    id="menu-nav-mob-myauction"
                                    onClick={navigateToMyAuctionList}
                                    sx={{ my: 2 }}
                                    aria-controls={mobileMenuId}
                                    className={userContext.rootContext.selectedMenu == 'myAuction' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                                >{<PersonIcon className={classes.menuButtonIcon} />}
                                </Button>
                            </Tooltip>
                        </Box>
                    }

                    {/* _________ multi auction starts from here ____________ */}
                    {userContext.rootContext.userSession.isAuthenticated &&
                        <Box sx={{ display: { xs: 'none', md: 'inline-flex' } }}>
                            <Button
                                id="menu-nav-mob-multi-auction-bidder"
                                onClick={navigateToMultiAuctionBidder}
                                sx={{ my: 2 }}
                                aria-controls={menuId}
                                className={userContext.rootContext.selectedMenu == 'multiAuctionBidder' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                            >{<PersonIcon className={classes.menuButtonIcon} />}{t('Multi_Auction')}
                            </Button>
                        </Box>
                    }
                    {userContext.rootContext.userSession.isAuthenticated &&
                        <Box sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}>
                            <Tooltip arrow="true" title={t('Multi_Auction')}>
                                <Button
                                    id="menu-nav-mob-multi-auction-bidder"
                                    onClick={navigateToMultiAuctionBidder}
                                    sx={{ my: 2 }}
                                    aria-controls={mobileMenuId}
                                    className={userContext.rootContext.selectedMenu == 'multiAuctionBidder' ? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                                >{<PersonIcon className={classes.menuButtonIcon} />}
                                </Button>
                            </Tooltip>
                        </Box>
                    }
                    {/* _________ multi auction ends here ____________ */}
                    
                    {/* {process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <Box>
                         <Button
                             id="old-nbid"
                             sx={{ my: 2 }}
                             aria-controls={menuId}
                             className={classes.menuButton} color='warning'
                         >
                             <Link target="_parent" href={process.env.REACT_APP_NBID_URL} color='warning'
                                 style={{ cursor: 'pointer'}}
                                 underline='none'
                             >
                                 <OpenInNewRoundedIcon className={classes.menuButtonIcon} />
                                 {t('Old_Nbid')}
                             </Link>
                         </Button>
                     </Box>} */}
                    {/* <Box>
                        <Button
                            onClick={NavigateToRoles}
                            sx={{ my: 2}}
                            aria-controls={menuId}
                            className={ userContext.selectedMenu == 'auction'? ([classes.menuButton, classes.activeMenuButton].join(' ')) : classes.menuButton}
                        >{t('Roles')}
                        </Button>
                    </Box> */}
                    <Box sx={{ flexGrow: 2 }} />
                    {userContext.rootContext.userSession.isAuthenticated && <Box>
                        {userContext.rootContext.currentDateTime && <AuctionCurrentTimer currentDate={userContext.rootContext.currentDateTime} />}
                    </Box>}
                    {/* {userContext.userSession.isAuthenticated && <Tooltip TransitionComponent={Zoom} title="Light">
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <IconButton id="lighttheme" onClick={(event) => updateTheme(event, 'light')}>
                                <IconSun
                                    value="light"
                                    size={32}
                                    strokeWidth={2}
                                    color={'#ced279'}
                                />
                            </IconButton>
                        </Box>
                    </Tooltip>}
                    {userContext.userSession.isAuthenticated && <Tooltip TransitionComponent={Zoom} title="Dim">
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <IconButton id="dimtheme" onClick={(event) => updateTheme(event, 'dim')}>
                                <IconMoon
                                    size={25}
                                    strokeWidth={2}
                                    color={'#4057bf'}
                                />
                            </IconButton>
                        </Box>
                    </Tooltip>}
                    {userContext.userSession.isAuthenticated && <Tooltip TransitionComponent={Zoom} title="Dark">
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <IconButton id="darktheme" onClick={(event) => updateTheme(event, 'dark')}>
                                <Brightness1 style={{ color: 'black', borderRadius: '50%', border: '1px solid #fff' }}
                                    size={45}
                                    strokeWidth={1}
                                    color={'#e2e6b3'}
                                />
                            </IconButton>
                        </Box>
                    </Tooltip>} */}
                    {/* {userContext.rootContext.userSession.isAuthenticated && <Box>
                        <IconButton className={classes.headerIcons}
                            size="small"
                            onClick={toggleDrawer('support', true)}
                            color="inherit"
                            id="help-guide"
                        >
                            <ContactSupportIcon />
                        </IconButton>
                    </Box>} */}
                    {userContext.rootContext.userSession.isAuthenticated && <Box>
                        <IconButton className={classes.headerIcons}
                            size="small"
                            onClick={toggleDrawer('settings', true)}
                            color="inherit"
                            id="quick-settings"
                        >
                            <SettingsIcon />
                        </IconButton>
                    </Box>} 
                    {userContext.rootContext.userSession.isAuthenticated && <Box sx={{ display: { xs: 'none', md: 'flex' }, padding: '0px 5px' }}>
                        <IconButton
                            size="small"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            id="prosetting"
                        >
                            {/* <Avatar>
                                <Person />
                            </Avatar> */}
                            <AvatarName width='34px' height='34px' fontSize='1rem' />
                        </IconButton>
                    </Box>}
                    {userContext.rootContext.userSession.isAuthenticated && <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            idd="showmore"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>}
                    {!userContext.rootContext.userSession.isAuthenticated && <Box>
                        <Button
                            id="sign-in"
                            onClick={onSignUp}
                            sx={{ my: 2 }}
                            aria-controls={menuId}
                            className={classes.menuButton}
                        >{t('Sign_Up')}</Button>
                    </Box>}
                    {/* {!userContext.isAuthenticated && <Box sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}>
                        <Button
                            id="sign-in"
                            onClick={onSignUp}
                            sx={{ my: 2 }}
                            aria-controls={mobileMenuId}
                            className={classes.menuButton}
                        >{t('Sign_Up')}</Button>
                    </Box>} */}
                    {!userContext.rootContext.userSession.isAuthenticated && <Box>
                        <Button
                            id="sign-in"
                            onClick={onLogin}
                            sx={{ my: 2 }}
                            aria-controls={menuId}
                            className={classes.menuButton}
                        >{t('Sign_In')}</Button>
                    </Box>}
                    {/* {!userContext.isAuthenticated && <Box sx={{ flexGrow: 1, display: { xs: 'inline-flex', md: 'none' } }}>
                        <Tooltip arrow="true" title={t('Sign_In')}>
                            <Button
                                id="sign-in"
                                onClick={onLogin}
                                sx={{ my: 2 }}
                                aria-controls={mobileMenuId}
                                className={classes.menuButton}
                            >{t('Sign_In')}</Button>
                        </Tooltip>
                    </Box>} */}
                    {!userContext.rootContext.userSession.isAuthenticated && <Box>
                        <Button
                            id="contact-us"
                            onClick={handleContactUs}
                            sx={{ my: 2 }}
                            aria-controls={menuId}
                            className={classes.menuButton}
                        >{t('Contact_Us')}</Button>
                    </Box>}
                    { process.env.REACT_APP_HOST_DOMAIN == AppHostDomain.nbid && <img src={process.env.PUBLIC_URL + './images/nesl-pda.png'} height={38} width={92} alt='NeslPDALogo' style={{ padding: '3px 0px 3px 10px' }}></img>}
                </Toolbar>
            </AppBar>
            {/* <Tooltip title="Live Customize">
                <Fab
                    component="div"
                    size="medium"
                    variant="circular"
                    color="primary"
                    sx={{
                        borderRadius: 0,
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '4px',
                        top: '65%',
                        position: 'fixed',
                        right: 10,
                        zIndex: 800
                    }}
                >
                    <IconButton color="inherit" size="large" disableRipple>
                        <Brush />
                    </IconButton>
                </Fab>
            </Tooltip> */}
            {renderMobileMenu}
            {renderMenu}
            {/* {errorOnUpdatingTheme && <GraphQLErrors error={errorOnUpdatingTheme} show={false} />} */}
            {message.showMsg && <AuctionSnackBar show={message.showMsg} message={message.message}
                severity={message.severity}>
            </AuctionSnackBar>}
            <QuickSettingsDrawer toggleDrawer={toggleDrawer} open={settingsDrawerState} />
            <SupportDrawer toggleDrawer={toggleDrawer} open={supportDrawerState} />
        </Box>
    );
}

export default TopBar